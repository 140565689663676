
const formattedNumber = (value, style = 'percent', minimumFractionDigits = 2) => {
  if(!value || isNaN(value) || value === Infinity) {
    value = 0;
  }

  return new Intl.NumberFormat('pt-BR', { style, currency: 'BRL', minimumFractionDigits }).format(value);
}

const formattedPercentage = (total = 0, value = 0) => {
  return formattedNumber(value / total);
}

export default formattedPercentage;
