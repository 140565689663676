import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';

const Card = ({ name, total }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round='xsmall'
          width={{ min: 'small', max: 'medium' }}
          pad={{
            vertical: size === 'small' ? 'large' : 'medium',
            horizontal: size === 'small' ? 'large' : 'medium',
          }}
          border={{ color: 'light-4' }}
          margin={{ bottom: 'small' }}
        >
          <Box
            direction='row'
            align='end'
          >
            <Text color='dark-1' size='large'>{total}</Text>
          </Box>

          <Box
            direction='row'
            justify='between'
            align='center'
            gap='small'
          >
            <Text truncate title={name} size='small' color='dark-6'>{name}</Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export { Card };
