import TYPES from '../actions/types';

const INITIAL_STATE = {
  statusServiceOptions: [],
  originServiceOptions: [],
  categoryServiceOptions: [],
  citiesOptions: [],
  districtsOptions: [],
  groupsOptions: []
};

const queriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.getStatusServiceOptions:
      return { ...state, statusServiceOptions: action.payload };

    case TYPES.getOriginServiceOptions:
      return { ...state, originServiceOptions: action.payload };

    case TYPES.getCategoryServiceOptions:
      return { ...state, categoryServiceOptions: action.payload };

    case TYPES.getCityOptions:
      return { ...state, citiesOptions: action.payload }

    case TYPES.getDistrictOptions:
      return { ...state, districtsOptions: action.payload };

    case TYPES.getGroupsOptions:
      return { ...state, groupsOptions: action.payload };

    default:
      return state;
  }
}

export { queriesReducer }
