import { combineReducers } from 'redux';
import { layerReducer } from '../../reducers/layers';
import { queriesReducer } from '../../reducers/select-options';
import { formReducer } from '../../reducers/form';
import { searchReducer } from '../../reducers/search';

const rootReducers = combineReducers({
  layerReducer,
  queriesReducer,
  formReducer,
  searchReducer,
});

export default rootReducers;
