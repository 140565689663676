import urlRoutes from './routes-url';

const menuOptions = [
  {
    label: 'Eleitores',
    click: urlRoutes.voters,
  },
  {
    label: 'Atendimentos',
    click: urlRoutes.services,
  }
];

export default menuOptions;
