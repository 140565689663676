import React from 'react';
import { ResponsiveContext, Box, Layer, Text, Select, Anchor, Button, RadioButtonGroup } from 'grommet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TitleSideBar, TitleField } from '../../../components';

import { closeLayer } from '../../../actions/layers';
import { searchCityOptions, searchDistrictOptions } from '../../../actions/select-options';
import { changeValues, cleanValues } from '../../../actions/form';
import { search } from '../../../actions/search';

import genderOptions from '../../../constants/gender';
import maritalStatusOptions from '../../../constants/marital-status';
import occupationOptions from '../../../constants/occupation';
import voterOptions from '../../../constants/voter-status';

const FormSearch = (props) => {
  if(!props.toggleLayer) return null;

  const onSearchCities = ({ text }) => {
    if (text.length < 2) return;

    const { searchCityOptions } = props;
    searchCityOptions({ name: text });
  }

  const onSearchDistricts = ({ cityId, text }) => {
    if (text.length < 2) return;

    const { searchDistrictOptions } = props;
    searchDistrictOptions({ cityId, name: text });
  }

  const onSearch = (params) => {
    const { search, closeLayer } = props;

    search({
      path: 'eleitor.estatistica.php', values: params
    });

    closeLayer();
  }

  const {
    // data
    groupOptions,
    citiesOptions,
    districtsOptions,

    // functions
    closeLayer,
    changeValues,
    cleanValues,

    // fields
    cityId,
    districtId,
    genderId,
    maritalStatusId,
    occupationId,
    voter,
    groupId,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          responsive={false}
          full='vertical'
          position='left'
          onClickOutside={closeLayer}
          onEsc={closeLayer}
        >
          <Box
            fill='vertical'
            width='60vw'
            pad={{ top: 'large', bottom: 'xsmall' }}
            overflow='auto'
            flex
          >
            <TitleSideBar value='Buscar' />

            {/* Campos de busca */}
            <Box flex overflow='auto'>

              { /* City */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Cidade' />

                  {cityId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => {
                        changeValues({ fieldName: 'cityId', value: null });
                        changeValues({ fieldName: 'districtId', value: null });
                      }}
                    />
                  )}
                </Box>

                <Select
                  options={citiesOptions}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  value={cityId ?? ''}
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ value: nextValue }) => changeValues({ fieldName: 'cityId', value: nextValue }) }
                  onSearch={ text => onSearchCities({ text }) }
                />
              </Box>

              { /* District */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Bairro' />

                  {districtId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'districtId', value: null })}
                    />
                  )}
                </Box>

                <Select
                  placeholder={!cityId ? 'Selecione uma cidade antes...' : ''}
                  searchPlaceholder={cityId ? 'Digite para buscar...' : 'Selecione uma cidade antes...'}
                  emptySearchMessage={cityId ? 'Nenhum resultado encontrado.' : 'Você ainda não selecionou uma cidade...'}
                  options={districtsOptions}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  dropHeight='small'
                  value={districtId ?? ''}
                  onChange={({ value: nextValue }) => changeValues({ fieldName: 'districtId', value: nextValue }) }
                  onSearch={ text => onSearchDistricts({ cityId, text }) }
                  disabled={!cityId}
                />
              </Box>

              { /* Gender */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Gênero' />

                  {genderId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'genderId', value: null })}
                    />
                  )}
                </Box>

                <Box
                  pad={{ vertical: 'small' }}
                >
                  <RadioButtonGroup
                    name='genderId'
                    value={genderId ?? ''}
                    options={
                      genderOptions.map(({ id, name }) => ({
                        label: name,
                        id: `genderId_${id}`,
                        value: id.toString(),
                      }))
                    }
                    onChange={event => { changeValues({ fieldName: 'genderId', value: event.target.value }) }}
                    wrap={true}
                    direction='row'
                  >
                    {(option, { checked }) => {
                      let background = null;
                      let borderColor = 'dark-5';

                      if(checked) {
                        background = 'brand';
                        borderColor = 'brand';
                      }

                      return (
                        <Box
                          direction='row'
                          gap='small'
                          align='center'
                          width={{ min: 'medium' }}
                          margin={{ bottom: 'small' }}
                        >
                          <Box
                            width={'20px'}
                            height={'20px'}
                            background={background}
                            round='xxsmall'
                            border={{
                              color: borderColor
                            }}
                          />
                          <Text color='dark-2' size='small'>{option.label}</Text>
                        </Box>
                      )
                    }}
                  </RadioButtonGroup>
                </Box>
              </Box>

              { /* Marital */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Estado Civil' />

                  {maritalStatusId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'maritalStatusId', value: null })}
                    />
                  )}
                </Box>

                <Box
                  pad={{ vertical: 'small' }}
                >
                  <RadioButtonGroup
                    name='maritalStatusId'
                    value={maritalStatusId ?? ''}
                    options={
                      maritalStatusOptions.map(({ id, name }) => ({
                        label: name,
                        id: `maritalStatusId_${id}`,
                        value: id.toString(),
                      }))
                    }
                    onChange={event => { changeValues({ fieldName: 'maritalStatusId', value: event.target.value }) }}
                    wrap={true}
                    direction='row'
                  >
                    {(option, { checked }) => {
                      let background = null;
                      let borderColor = 'dark-5';

                      if(checked) {
                        background = 'brand';
                        borderColor = 'brand';
                      }

                      return (
                        <Box
                          direction='row'
                          gap='small'
                          align='center'
                          width={{ min: 'medium' }}
                          margin={{ bottom: 'small' }}
                        >
                          <Box
                            width={'20px'}
                            height={'20px'}
                            background={background}
                            round='xxsmall'
                            border={{
                              color: borderColor
                            }}
                          />
                          <Text color='dark-2' size='small'>{option.label}</Text>
                        </Box>
                      )
                    }}
                  </RadioButtonGroup>
                </Box>
              </Box>

              { /* Occupation */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Profissão' />

                  {occupationId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'occupationId', value: null })}
                    />
                  )}
                </Box>

                <Select
                  options={occupationOptions}
                  labelKey='name'
                  valueKey={{ key: 'id', reduce: true }}
                  value={occupationId ?? ''}
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ value: nextValue }) =>
                    changeValues({ fieldName: 'occupationId', value: nextValue })
                  }
                />
              </Box>

              { /* Voter */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Status do voto' />

                  {voter && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'voter', value: null })}
                    />
                  )}
                </Box>

                <Box
                  pad={{ vertical: 'small' }}
                >
                  <RadioButtonGroup
                    name='voter'
                    value={voter ?? ''}
                    options={
                      voterOptions.map(({ id, name }) => ({
                        label: name,
                        id: `voter_${id}`,
                        value: id.toString(),
                      }))
                    }
                    onChange={event => { changeValues({ fieldName: 'voter', value: event.target.value }) }}
                    wrap={true}
                    direction='row'
                  >
                    {(option, { checked }) => {
                      let background = null;
                      let borderColor = 'dark-5';

                      if(checked) {
                        background = 'brand';
                        borderColor = 'brand';
                      }

                      return (
                        <Box
                          direction='row'
                          gap='small'
                          align='center'
                          width={{ min: 'medium' }}
                          margin={{ bottom: 'small' }}
                        >
                          <Box
                            width={'20px'}
                            height={'20px'}
                            background={background}
                            round='xxsmall'
                            border={{
                              color: borderColor
                            }}
                          />
                          <Text color='dark-2' size='small'>{option.label}</Text>
                        </Box>
                      )
                    }}
                  </RadioButtonGroup>
                </Box>
              </Box>

              { /* Group */ }
              <Box
                margin={{
                  vertical: size === 'small' ? 'medium' : 'small',
                  horizontal: 'large'
                }}
                flex={{ shrink: 0 }}
              >
                <Box direction='row' align='center' gap='xsmall'>
                  <TitleField text='Grupo' />

                  {groupId && (
                    <Anchor
                      size='small'
                      label='limpar'
                      onClick={() => changeValues({ fieldName: 'groupId', value: null })}
                    />
                  )}
                </Box>

                <Box
                  pad={{ vertical: 'small' }}
                >
                  <RadioButtonGroup
                    name='groupId'
                    value={groupId ?? ''}
                    options={
                      groupOptions.map(({ id, name }) => ({
                        label: name,
                        id: `groupId_${id}`,
                        value: id.toString(),
                      }))
                    }
                    onChange={event => { changeValues({ fieldName: 'groupId', value: event.target.value }) }}
                    wrap={true}
                    direction='row'
                  >
                    {(option, { checked }) => {
                      let background = null;
                      let borderColor = 'dark-5';

                      if(checked) {
                        background = 'brand';
                        borderColor = 'brand';
                      }

                      return (
                        <Box
                          direction='row'
                          gap='small'
                          align='center'
                          width={{ min: 'medium' }}
                          margin={{ bottom: 'small' }}
                        >
                          <Box
                            width={'20px'}
                            height={'20px'}
                            background={background}
                            round='xxsmall'
                            border={{
                              color: borderColor
                            }}
                          />
                          <Text color='dark-2' size='small'>{option.label}</Text>
                        </Box>
                      )
                    }}
                  </RadioButtonGroup>
                </Box>
              </Box>

            </Box>

            {/* Botões Busca/limpa */}
            <Box
              direction='row'
              pad={{ vertical: 'medium' }}
              margin={{ horizontal: 'large' }}
              justify='center'
              align='center'
              gap='small'
            >
              <Box width='small'>
                <Button
                  color='dark-3'
                  label='Limpar'
                  onClick={() => cleanValues() }
                />
              </Box>

              <Box width='small'>
                <Button
                  color='brand'
                  primary
                  label='Buscar'
                  onClick={() =>
                    onSearch({ cityId, districtId, genderId, maritalStatusId, occupationId, voter, groupId })
                  }
                />
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ layerReducer, queriesReducer, formReducer }) => ({
  toggleLayer: layerReducer.toggleLayer,
  groupOptions: queriesReducer.groupsOptions,
  citiesOptions: queriesReducer.citiesOptions,
  districtsOptions: queriesReducer.districtsOptions,

  cityId: formReducer.cityId,
  districtId: formReducer.districtId,
  genderId: formReducer.genderId,
  maritalStatusId: formReducer.maritalStatusId,
  occupationId: formReducer.occupationId,
  voter: formReducer.voter,
  groupId: formReducer.groupId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    closeLayer,
    searchCityOptions,
    searchDistrictOptions,
    changeValues,
    cleanValues,
    search,
  }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FormSearch);
