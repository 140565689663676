import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';

const Step = ({ index, description }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          round={ size === 'small' ? 'small' : 'xsmall'}
          border={{
            side: 'all',
            color: 'light-4',
          }}
          pad={{
            vertical: size === 'small' ? 'medium' : 'small',
            horizontal: 'medium',
          }}
          align='center'
          direction='row'
          gap='medium'
        >
          {index && (<Text color='dark-2' size='xlarge' weight='bold'>{index}.</Text>)}

          <Text color='dark-2' size='small'>
            {description}
          </Text>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export { Step };
