import { getCookie, decryptAes } from '../../utils';

const get = async () => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const response = await fetch(`${process.env.REACT_APP_HOST_API}/dashboard.php?ac=getDetailsServices`, {
    method: 'GET',
    headers: {
      token: decipher.token.value,
      action: 'getDetailsServices',
    },
  });

  return await response.json();
}

export default get;
