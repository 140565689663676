import React from 'react';
import { Box, Text, ResponsiveContext, Button } from 'grommet';
import { StatusCritical } from 'grommet-icons';

const ErrorSearches = (props) => {
  const {
    title = 'Ops! Algo deu errado :(',
    message = 'Estamos constrangidos por isso.',
    button = null
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          align='center'
          justify='center'
          height='100vh'
        >
          <Box
            align='center'
            width='medium'
            pad={ size === 'small' ? 'large' : 'small' }
          >
            <StatusCritical size='large' color='status-critical' />

            <Box
              direction={size !== 'small' ? 'row' : 'column'}
              margin={{ vertical: 'large' }}
              gap='medium'
            >
              <Box>
                <Text
                  size='xlarge'
                  textAlign={size !== 'small' ? 'end' : 'center'}
                >
                  {title}
                </Text>
              </Box>

              <Box>
                <Text
                  size='small'
                  color='dark-5'
                  textAlign={size !== 'small' ? 'start' : 'center'}
                >
                  {message}
                </Text>
              </Box>
            </Box>

            {button && (
              <Button
                label={button.label}
                color='dark-3'
                onClick={() => { button.action() }}
              />
            )}
          </Box>
        </Box>
      )}

    </ResponsiveContext.Consumer>
  )
}

export default ErrorSearches;
