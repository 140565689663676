import { getCookie, decryptAes } from '../../utils';

const queries = async (ac) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const response = await fetch(`${process.env.REACT_APP_HOST_API}/queries.php?ac=${ac}`, {
    method: 'GET',
    headers: {
      token: decipher.token.value,
      action: ac,
    },
  });

  return await response.json();
}

export default queries;
