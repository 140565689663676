import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Result from '../components/result';
import { Header } from '../../../components';

import { getDashboard } from '../../../actions/search';

const DashboardSystem = (props) => {
  useEffect(() => {
    const {
      getDashboard
    } = props;

    getDashboard({
      path: 'dashboard.php',
      values: {}
    });

  }, [props]);

  return (
    <>
      <Header />
      <Result />
    </>
  )
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getDashboard
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSystem);
