const maritalStatusOptions = [
  { id: '1', name: 'Casado(a)' },
  { id: '2', name: 'Divorciado(a)' },
  { id: '3', name: 'Separado(a)' },
  { id: '4', name: 'Solteiro(a)' },
  { id: '5', name: 'Viúvo(a)' },
  { id: '6', name: 'União Estável' }
];

export default maritalStatusOptions;
