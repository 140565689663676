import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Box, Button, Text, Tip } from 'grommet';
import { Search, Download } from 'grommet-icons';

import { Card } from './card';
import { EmptySearches, ErrorSearches, LoadSearches } from '../../../components';

import { openLayer } from '../../../actions/layers';

import { formattedNumber, formattedPercentage } from '../../../utils';

const SearchResult = (props) => {
  const {
    // functions
    openLayer,

    //data
    dataRows,
    loading,
    error
  } = props;

  if(loading && !error) {
    return (
      <LoadSearches
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    );
  }

  if(error) {
    return <ErrorSearches
      button={{
        label: 'Abrir filtros',
        action: openLayer
      }}
    />;
  }

  const searchTotal = dataRows.find(({ type }) => type === 'searchTotal')?.total || 0;

  if(searchTotal === 0) {
    return <EmptySearches
      buttonFilters={{
        label: 'Abrir filtros',
        action: openLayer,
      }}
    />;
  }

  const geralTotal = dataRows.find(({ type }) => type === 'total')?.total || 0;
  const districts = dataRows.filter(({ type }) => type === 'district');
  const cities = dataRows.filter(({ type }) => type === 'city');
  const genders = dataRows.filter(({ type }) => type === 'gender');
  const maritalStatus = dataRows.filter(({ type }) => type === 'maritalStatus');
  const occupations = dataRows.filter(({ type }) => type === 'occupation');
  const groups = dataRows.filter(({ type }) => type === 'group');
  const voters = dataRows.filter(({ type }) => type === 'voter');

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='row'
          justify='center'
          margin={{ horizontal: 'medium', top: '80px' }}
        >
          <Box
            pad='small'
            width='xlarge'
          >
            <Box
              align='center'
              direction='row'
              justify='between'
              margin={{ bottom: 'small' }}
            >
              <Box
                direction={size === 'small' ? 'column' : 'row'}
                align={size === 'small' ? 'baseline' : 'center'}
                gap={size === 'small' ? null : 'small'}
              >
                <Text color='dark-1' size='xlarge'>{searchTotal}</Text>

                <Text
                  size={size === 'small' ? 'xsmall' : 'medium'}
                  color='dark-6'
                >
                  eleitor(es)
                </Text>
              </Box>

              <Box
                direction='row'
                align='center'
                gap={size === 'small' ? 'large' : 'medium'}
              >
                <Tip
                  content={
                    <Box
                      background='light-4'
                      pad='xxsmall'
                    >
                      <Text size='xsmall' color='dark-2'>Estamos construindo essa funcionalidade, Aguarde!</Text>
                    </Box>
                  }
                  plain
                >
                  <Button
                    plain
                    //title='Download Excel (.csv)'
                    icon={<Download />}
                    size='small'
                    //onClick={() => { alert('Estamos construindo essa funcionalidade!') }}
                  />
                </Tip>

                <Button
                  plain
                  title='Filtrar'
                  icon={<Search />}
                  onClick={() => { openLayer() }}
                />
              </Box>
            </Box>

            {/* Por cidade */}
            {!!cities.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por cidade</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  cities.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por bairro */}
            {!!districts.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por bairro</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  districts.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por Genero */}
            {!!genders.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por gênero</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  genders.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por grupo */}
            {!!groups.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por grupo</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  groups.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por estado civil */}
            {!!maritalStatus.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por estado civil</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  maritalStatus.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por profissão */}
            {!!occupations.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Por profissão</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  occupations.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}

            {/* Por status voto */}
            {!!voters.length && (<Box
              gap='small'
              margin={{ vertical: 'medium' }}
            >
              <Box
                gap='xsmall'
              >
                <Text color='brand' size='medium'>Status do voto</Text>
              </Box>

              <Box
                direction='row'
                gap={ size === 'small' ? 'large' : 'small' }
                wrap={true}
              >
                {
                  voters.map(({ name, total }) => (
                    <Card
                      key={name}
                      name={name}
                      total={formattedNumber(total)}
                      percentage={formattedPercentage(geralTotal, total)}
                    />
                  ))
                }
              </Box>
            </Box>)}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ searchReducer }) => ({
  dataRows: searchReducer.dataRows,
  loading: searchReducer.loading,
  error: searchReducer.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ openLayer }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
