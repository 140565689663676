const configsGrommet = {
  global: {
    colors: {
      brand: {
        dark: '#3068a2',
        light: '#3068a2'
      },
      border: {
        light: 'rgba(0, 0, 0, 0.15)',
      }
    },

    drop: {
      border: { radius: '.3rem' },
      //extend: { marginTop: '.3rem' },
      shadowSize: 'medium',
    },

    breakpoints: {
      small: {
        value: 768
      },
      medium: {
        value: 992
      },
      large: {
        value: 1200
      },
      xlarge: {
        value: 3000
      }
    },

    input: {
      weight: 400
    },

    focus: {
      border: { color: 'none' }
    }
  },

  tabs: {
    gap: 'xxsmall',
    header: {
      border: {
        side: 'bottom',
        color: 'light-3',
        size: 'small',
      }
    }
  },
  tab: {
    pad: 'small',
    border: {
      side: 'bottom',
      color: 'light-3',
    },
    margin: {
      // bring the overall tabs border behind invidual tab borders
      vertical: '-2px',
      horizontal: 'none',
    },
  },

  radioButton: {
    //gap: "xsmall",
    size: '18px',
    /*hover: {
      border: {
        color: 'dark-3'
      }
    },
    check: {
      color: {
        light: 'neutral-1'
      }
    },*/
  },

  anchor: {
    fontWeight: 400
  },

  button: {
    border: {
      radius: '.3rem'
    },

    size: {
      small: {
        border: {
          radius: '.3rem'
        },
        pad: {
          horizontal: '15px'
        },
      },
      medium: {
        border: {
          radius: '.3rem'
        }
      },
      large: {
        border: {
          radius: '.3rem'
        }
      },
    },
  },
}

export default configsGrommet;
