import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SearchResult from '../components/search-result';
import FormSearch from '../components/form-search';
import { Header } from '../../../components';

import { getStatusServiceOptions, getOriginsServiceOptions, getCategoriesServiceOptions } from '../../../actions/select-options';
import { search } from '../../../actions/search';

const DashboardService = (props) => {
  useEffect(() => {
    const {
      getStatusServiceOptions, getOriginsServiceOptions, getCategoriesServiceOptions, search
    } = props;

    search({
      path: 'atendimento.estatistica.php',
      values: {}
    });

    getStatusServiceOptions();
    getOriginsServiceOptions();
    getCategoriesServiceOptions();
  }, [props]);

  return (
    <>
      <Header />

      <SearchResult />
      <FormSearch />
    </>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispath =>
  bindActionCreators({
    getStatusServiceOptions,
    getOriginsServiceOptions,
    getCategoriesServiceOptions,
    search,
  }, dispath)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardService);
