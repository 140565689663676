import TYPES from './types';

export const changeValues = (params) => ({
  type: TYPES.formChangeValues,
  payload: params,
});

export const cleanValues = () => ({
  type: TYPES.formCleanValues,
});
