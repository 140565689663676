import TYPES from '../actions/types';

const INITIAL_STATE = {}

const formReducer = (state = INITIAL_STATE, action) => {
  const acType = TYPES[action.type] ?? action.type;

  switch(acType) {
    case TYPES.formChangeValues:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }

    case TYPES.formInitializeValues:
      return {
        ...state,
        ...action.payload,
      }

    case TYPES.formCleanValues:
      return {
        ...INITIAL_STATE,
      }

    default:
      return state;
  }
}

export { formReducer }
