import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SearchResult from '../components/search-result';
import FormSearch from '../components/form-search';
import Header from '../../../components/header';

import { getGroupsOptions } from '../../../actions/select-options';
import { search } from '../../../actions/search';

const DashboardVoter = (props) => {
  useEffect(() => {
    const { getGroupsOptions, search } = props;

    search({
      path: 'eleitor.estatistica.php',
      values: {}
    });

    getGroupsOptions();

  }, [props]);

  return (
    <>
      <Header />

      <SearchResult />
      <FormSearch />
    </>
  )
}

const mapStateToProps = null

const mapDispatchToProps = dispath =>
  bindActionCreators({
    getGroupsOptions,
    search,
  }, dispath)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardVoter);
