import React from 'react';
import { Text } from 'grommet';

const TitleField = ({ text, required }) =>  (
  <Text size='small' color='dark-2' textAlign='start'>
    {text} {required ? '*' : ''}
  </Text>
)

export default TitleField;
