import { getCookie, decryptAes } from '../../utils';

const queries = async ({ cityId, name }) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  const params = new URLSearchParams({
    cityId,
    name,
    timestamp: new Date().getTime(),
  })

  const response = await fetch(`${process.env.REACT_APP_HOST_API}/bairro.php?${params}`, {
    method: 'GET',
    headers: {
      token: decipher.token.value,
      action: 'list',
    },
  });

  return await response.json();
}

export default queries;
