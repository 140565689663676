import TYPES from '../actions/types';

const INITIAL_STATE = {
  toggleLayer: null,
}

const layerReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.toggleLayer:
      return { ...state, toggleLayer: action.payload }

    default:
      return state;
  }
}

export { layerReducer, TYPES }
